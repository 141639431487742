Vue.component("navigation-item-component", {
    data: function () {
        return {
            isActive: false,
        }
    },
    methods: {
        toggle: function () {
            this.isActive = !this.isActive;
        },
    },
});
