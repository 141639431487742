Vue.component('intro-component', {
    data() {
        return {
            slider1J: null,
            slider2A: null,
            slider3C: null,
            slider4O: null,
            slider5B: null,
            slider6A: null,
            backgroundSlider: null,
            sliderSetup: {
                pageDots: false,
                prevNextButtons: false,
                draggable: false,
                wrapAround: true,
                fade: true,
                lazyLoad: true,
                setGallerySize: false,
            }
        }
    },
    mounted() {
        this.initSlider1J();
        this.initSlider2A();
        this.initSlider3C();
        this.initSlider4O();
        this.initSlider5B();
        this.initSlider6A();
        this.initBackgroundSlider();
        this.playSliders();

        let that = this;

        window.addEventListener('resize', function(){
            that.resizeSliders();
        });
    },
    methods: {
        initSlider1J() {
            this.slider1J = new Flickity(this.$refs.theSlider1J, this.sliderSetup);
        },
        initSlider2A() {
            this.slider2A = new Flickity(this.$refs.theSlider2A, this.sliderSetup);
        },
        initSlider3C() {
            this.slider3C = new Flickity(this.$refs.theSlider3C, this.sliderSetup);
        },
        initSlider4O() {
            this.slider4O = new Flickity(this.$refs.theSlider4O, this.sliderSetup);
        },
        initSlider5B() {
            this.slider5B = new Flickity(this.$refs.theSlider5B, this.sliderSetup);
        },
        initSlider6A() {
            this.slider6A = new Flickity(this.$refs.theSlider6A, this.sliderSetup);
        },
        initBackgroundSlider() {
            this.backgroundSlider = new Flickity(this.$refs.theBackgroundSlider, {
                pageDots: false,
                prevNextButtons: false,
                draggable: false,
                wrapAround: true,
                fade: true,
                lazyLoad: true,
                setGallerySize: false,
            });
        },
        playSliders() {
            let that = this;

            setInterval(function(){
                that.backgroundSlider.next();

                setTimeout(function () {
                    that.slider1J.next();
                }, Math.floor(Math.random() * 1000));

                setTimeout(function () {
                    that.slider2A.next();
                }, Math.floor(Math.random() * 1000));

                setTimeout(function () {
                    that.slider3C.next();
                }, Math.floor(Math.random() * 1000));

                setTimeout(function () {
                    that.slider4O.next();
                }, Math.floor(Math.random() * 1000));

                setTimeout(function () {
                    that.slider5B.next();
                }, Math.floor(Math.random() * 1000));

                setTimeout(function () {
                    that.slider6A.next();
                }, Math.floor(Math.random() * 1000));

                setTimeout(function () {
                    that.slider1J.next();
                }, Math.floor(Math.random() * 1000) + 2000);

                setTimeout(function () {
                    that.slider2A.next();
                }, Math.floor(Math.random() * 1000) + 2000);

                setTimeout(function () {
                    that.slider3C.next();
                }, Math.floor(Math.random() * 1000) + 2000);

                setTimeout(function () {
                    that.slider4O.next();
                }, Math.floor(Math.random() * 1000) + 2000);

                setTimeout(function () {
                    that.slider5B.next();
                }, Math.floor(Math.random() * 1000) + 2000);

                setTimeout(function () {
                    that.slider6A.next();
                }, Math.floor(Math.random() * 1000) + 2000);
            }, 5000);
        },
        resizeSliders() {
            this.slider1J.resize();
            this.slider2A.resize();
            this.slider3C.resize();
            this.slider4O.resize();
            this.slider5B.resize();
            this.slider6A.resize();
            this.backgroundSlider.resize();
        },
        scrollMain() {
            var el = document.getElementById('main');

            if(el !== null) {
                window.scrollTo({
                    top: el.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
    }
});
