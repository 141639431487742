Vue.component('quote-slider', {
   data() {
       return {
           flkty: null,
       }
   },
   mounted: function() {
        this.initSlider();
    },
    methods: {
        initSlider() {
            let that = this;
            this.flkty = new Flickity(this.$refs.quoteSlider, {
                pageDots: false,
                draggable: "ontouchstart" in window,
                wrapAround: true,
                arrowShape: "M23.741 46.0002L58.6331 7.45768L1.8413e-06 46.0002L58.6331 84.5427L23.741 46.0002ZM100 91.2383L60.8813 46.0002L100 0.762092L35.2518 46.0002L100 91.2383Z",
                on: {
                    ready: function() {
                        setTimeout(function(){ that.$root.anchorScroll(); }, 500);
                    }
                }
            });
        },
    }
});