Vue.component("slider-component", {
    data: function () {
        return {
            flickityOptions: {
                prevNextButtons: false,
                pageDots: false,
                selectedAttraction: .01,
                friction: .75,
                imagesLoaded: true,
                setGallerySize: false,
                resize: true,
                contain: true,
                wrapAround: false,
                cellAlign: 'left',
                fade: true,
                draggable: true,
                bgLazyLoad: true,
            },
            flickity: null,
            slides: 0,
            activeSlide: 1,
        }
    },
    mounted: function () {
        this.$nextTick(() => {
            this.init()
        })
    },
    methods: {
        init() {
            this.flickity = new Flickity(this.$refs.flickity, this.flickityOptions)
            this.slides = this.flickity.slides.length

            this.flickity.on('change', (index) => {
                // Set correct index when slide is swiped
                this.activeSlide = index + 1
            })
        },
        resize() {
            this.flickity.destroy()
            this.activeSlide = 1

            this.init()
        },
        next() {
            this.flickity.next()

            console.log('aa')
        },
        previous() {
            this.flickity.previous()
        },
    },
});
